import './style.css';
import about from './about-1.png'
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined';
import portfolio from './portfolio-8.jpg';
import brand1 from './brand-01.png';
import brand2 from './brand-02.png';
import brand3 from './brand-03.png';
import brand4 from './brand-04.png';
import brand5 from './brand-05.png';
import brand6 from './brand-06.png';
import brand7 from './brand-07.png';
import brand8 from './brand-08.png';
import brand9 from './brand-09.png';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Container,Link } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
function App() {
  const classes = useStyles();
  const settings = {
    centerMode: false,
 
  slidesToShow: 7,
  speed:1000,
  autoplay: true,
  infinite: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
          centerMode: false,
  
    autoplay: true,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
          centerMode: false,
    autoplay: true,
        },
      },
    ],
  };
  return (
    <div className="App">
  
     <div className="Homesection">
       {/* <img className="logo" src="/NFT-logo.png" /> */}
     <div className="title"><h1>The Indian Art Revolution</h1>
     <h3>Welcome to the Indian digital art,  entertainment and tourism revolution.</h3>
     <Link to="/#">Visit Marketplace</Link> </div>
     <div className="ImageSlide">
     <div className="ImageSlideBox">
    
       <div className="imgslide"><img src="/img.jpg" alt="nft" /></div>
       <div className="imgslide"><img src="/img2.jpg" alt="nft" /></div>
       <div className="imgslide"><img src="/img3.jpg" alt="nft" /></div>
       <div className="imgslide"><img src="/img4.jpg" alt="nft" /></div>
       <div className="imgslide"><img src="/img5.jpg" alt="nft" /></div>
       <div className="imgslide"><img src="/img6.jpg" alt="nft" /></div>
      
     
   

      
      
     </div>
     </div>
     </div>
     <div className="ourProject">
      <div className="headingText">
    
       <h2>The Platform</h2>
       <p className="subtittlepara">The exclusive decentralized network to provide premium Indian digital collectibles and much more.</p>
      </div>
       <div className="ourprojectrow">
       <Container maxWidth="lg">
     <Grid container spacing={3}>
     <Grid item xs={12} sm={3}>
       <div className="contentBox">
       <div className="contentDetails">
       <img src="/cart.png" alt="" />
         <h3>NFT marketplace</h3>
         <p>Upload,sell and buy aNFTs and NFTs on our exclusive platform with minimal fees.</p>
       </div>
       </div>
     </Grid>
     <Grid item xs={12} sm={3}>
       <div className="contentBox">
       <div className="contentDetails">
       <img src="/original.png" alt="" />
         <h3>Authentication of NFTs</h3>
         <p>Create art of our endorsed celebrities and apply to get it authenticated by the celebrity himself.</p>
       </div>
       </div>
     </Grid>
     <Grid item xs={12} sm={3}>
       <div className="contentBox">
       <div className="contentDetails">
       <img src="/approved.png" alt="" />
         <h3>Hiring an Artist</h3>
         <p>A hiring platform to hire TIAR exclusive artist for your desired product in given time governed by TIAR.</p>
       </div>
       </div>
     </Grid>
    
     <Grid item xs={12} sm={3}>
       <div className="contentBox">
       <div className="contentDetails">
       <img src="/gift.png" alt="" />
         <h3>Upload content for rewards</h3>
         <p>Now upload your creativity limited to India in any way and earn tokens/rewards. The content is limited to blogs,Vlogs and podcasts.</p>
       </div>
       </div>
     </Grid>
     <Grid item xs={12} sm={3}>
       <div className="contentBox">
       <div className="contentDetails">
       <img src="/approved.png" alt="" />
         <h3>Hire an Influencer</h3>
         <p>TIAR is the premium bridge between influencers and fans.</p>
       </div>
       </div>
     </Grid>
     <Grid item xs={12} sm={3}>
       <div className="contentBox">
       <div className="contentDetails">
       <img src="/world.png" alt="" />
         <h3>Travel planner</h3>
         <p>Get customised city guides for your stay in India personalised based on your interests.</p>
       </div>
       </div>
     </Grid>
     <Grid item xs={12} sm={3}>
       <div className="contentBox">
       <div className="contentDetails">
       <img src="/megaphone.png" alt="" />
         <h3>Advertise/promote Indian places on the platform</h3>
         <p>Promote your business/restaurant/hotel on the TIAR exclusive information centre for an elite audience.</p>
       </div>
       </div>
     </Grid>
     <Grid item xs={12} sm={3}>
       <div className="contentBox">
       <div className="contentDetails">
       <img src="/heart.png" alt="" />
         <h3>Donate to multiple organizations through the platform</h3>
         <p>Donate directly to an NGO, as TIAR has premium wallets handled by the respective partnered NGOs.</p>
       </div>
       </div>
     </Grid>
     <Grid item xs={12} sm={3}>
       <div className="contentBox">
       <div className="contentDetails">
       <img src="/businessman.png" alt="" />
         <h3>Indian professional boxing ppv</h3>
         <p>First Indian Pro Boxing exclusive PPV platform hosting it's own tournament and league in India.</p>
       </div>
       </div>
     </Grid>
     <Grid item xs={12} sm={3}>
       <div className="contentBox">
       <div className="contentDetails">
       <img src="/merchandising.png" alt="" />
         <h3>NFT merchandise</h3>
         <p>Ownership of the contract was renounced on day one. This means that the code cannot be changed by anyone.</p>
       </div>
       </div>
     </Grid>
     <Grid item xs={12} sm={3}>
       <div className="contentBox">
       <div className="contentDetails">
       <img src="/console.png" alt="" />
         <h3>Blockchain based NFT game</h3>
         <p>Game development starts post January 2022. Stay tuned!.</p>
       </div>
       </div>
     </Grid>
     </Grid></Container>
        {/* <Grid item xs={12} sm={4}>
        <div className="im_portfolio">
          <figure><img src={portfolio} alt="error" /></figure>

          <div class="content">
            <div class="inner">
              <div class="portfolio_heading">
                <div class="category_list">
                  <a href="/portfolio-details">Development</a>
                  </div>
                  <h4 class="title"><a href="#">Lorem ipsum</a></h4>
                  </div>
                  <div class="portfolio_hover">
                    <p>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.</p>
                    </div>
                    </div>
                    </div>
          <a class="transparent_link" href="#"></a>
        </div>
        </Grid>
        <Grid item xs={12} sm={4}>
        <div className="im_portfolio">
          <figure><img src={portfolio} alt="error" /></figure>

          <div class="content">
            <div class="inner">
              <div class="portfolio_heading">
                <div class="category_list">
                  <a href="/portfolio-details">Development</a>
                  </div>
                  <h4 class="title"><a href="/portfolio-details">Lorem ipsum</a></h4>
                  </div>
                  <div class="portfolio_hover">
                    <p>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.</p>
                    </div>
                    </div>
                    </div>
          <a class="transparent_link" href="#"></a>
        </div>
      
        </Grid>
        <Grid item xs={12} sm={4}>
        <div className="im_portfolio">
          <figure><img src={portfolio} alt="error" /></figure>

          <div class="content">
            <div class="inner">
              <div class="portfolio_heading">
                <div class="category_list">
                  <a href="/portfolio-details">Development</a>
                  </div>
                  <h4 class="title"><a href="/portfolio-details">Lorem ipsum</a></h4>
                  </div>
                  <div class="portfolio_hover">
                    <p>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.</p>
                    </div>
                    </div>
                    </div>
          <a class="transparent_link" href="/portfolio-details"></a>
        </div>
      
        </Grid> */}

   
       
       </div>
  </div>

     <div className="aboutarea bgblack">
       <Container maxWidth="lg">
     <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
        <div className="image">
      <img src="/about.jpg" alt="error" />
      </div>
        </Grid>
        <Grid item xs={12} sm={6}>
        <div className="abouttext">
        {/* <div className="icon">
       <NearMeOutlinedIcon/>
        </div> */}
        <h2>About the token:</h2>
        <div className="agendaBox text-white">
        <ul> 
           <li>Renouncing ownership on day 1.</li>
           <li>No slippage ( Sorry, not a ponzy scheme ).</li>
           <li>Token burn through the revenue generated.</li>
           <li>A lot of utility.</li>
           <li>Audit (soon) and safex.</li>
           <li>low transaction fees.</li>
         </ul>
         </div>
        {/* <div className="purchasebutton">
        <a>PURCHASE IMROZ</a>
        </div> */}
      </div>
        </Grid>

      
      </Grid></Container>
      <div> 


      
     </div>
     </div>
  
     
  
  <div className="ourProject">
  <div className="headingText">
   
       <h2>Initial Tokenomics</h2>
       <p className="subtittlepara">The ultimate utility token for purshase of premium Indian digital collectibles and exclusive entertainment with anti - rug features and no dev wallet. </p>
      </div>

      <div className="text-center text-white">
        <img src="/graphn.png" alt="" className="img-responsive" />
        <p>Marketing wallet and Authenticator wallet = 5% + 15% ( 6 month locked vesting )</p>
        <p>locked liquidity pool = 20%</p>
        <p>Pre-sale = 60%</p>
      </div>
  </div>

  <div className="ourProject bgblack">
  <div className="headingText">
       <h2>Agenda</h2>
       <p className="subtittlepara">To be India's most favourite decentralized platform in the next 6 months.</p>
       </div>
       <Container maxWidth="lg">
     <Grid container spacing={3}>
     <Grid item xs={12} sm={3}>
       <div className="agendaBox text-white">
     
      
         <h3>Phase 1 : The canvas</h3>
         <ul> 
           <li>The concept is brought to creation.</li>
           <li>Development of framework and business model.</li>
           <li>Hiring of core team and developers.</li>
           <li>Development of website.</li>
           <li>1st authenticator endorsement.</li>
         </ul>
         </div>
     </Grid>
     <Grid item xs={12} sm={3}>
       <div className="agendaBox text-white">
     
      
         <h3>Phase 2 : The Tools</h3>
         <ul> 
           <li>Primary/seed investors.</li>
           <li>Development of Dapp begins.</li>
           <li>Partnering with 2 more authenticators.</li>
           <li>Formation of social media community.</li>
           <li>Announcement of authenticator.</li>
           <li>Artist applications open.</li>
         </ul>
         </div>
     </Grid>
     <Grid item xs={12} sm={3}>
       <div className="agendaBox text-white">
     
      
         <h3>Phase 3 : The Art</h3>
         <ul> 
           <li>Whitelist Sale.</li>
           <li>IEO.</li>
           <li>ILO.</li>
           <li>Launch and listing of tokens.</li>
           <li>Launch of MVP.</li>
           <li>First auction and Donation.</li>
         </ul>
         </div>
     </Grid>
     <Grid item xs={12} sm={3}>
       <div className="agendaBox text-white">
     
      
         <h3>Phase 4 : The Masterpiece</h3>
         <ul> 
           <li>Paid Marketing.</li>
           <li>Development of Blockchain based game.</li>
           <li>Partnering with authenticators on large scale.</li>
           <li>Launch of all features on Dapp.</li>
           <li>Premium Tourism and Travel NFTs.</li>
           <li>Professional boxing PPV event.</li>
           <li>Launch of NFT merchandise.</li>
           <li> Launch of The game.</li>
          
         </ul>
         </div>
     </Grid>
     </Grid>
     </Container>
  </div>
        
      <div className="bgblack">
      <Container maxWidth="lg">
      <div className="aboutbottom">
       <div className="copyright">
       <span>Copyright © 2021</span><span> U74999TG2016PTC113290 CIN </span><span> Xterrestrial promotions pvt ltd</span>
       </div>
        <div>
          <h2 style={{color: 'white'}}>Social Media</h2>
        <FacebookIcon style={{color: 'white',marginLeft: '5px'}}/>
        <LinkedInIcon style={{color: 'white',marginLeft: '5px'}}/>
        <InstagramIcon style={{color: 'white',marginLeft: '5px'}}/>
        <TwitterIcon style={{color: 'white',marginLeft: '5px'}}/>
        </div>
        </div>
        </Container>
      </div>
   
    </div>
  );
}

export default App;
